import React, { useEffect, useState } from 'react';
import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBBtn,
  MDBIcon 
} from "mdbreact";
import { useSnackbar } from 'react-simple-snackbar';
import http from '../../../../services/http';

const branch = 3;

const geoOperators = [
  { value: 0, text: "=" },
  { value: 1, text: "Not" },
  { value: 6, text: "In list" },
  { value: 7, text: "Not in list" },
];

const ageOperators = [
  { value: 0, text: "=" },
  { value: 2, text: ">" },
  { value: 3, text: ">=" },
  { value: 4, text: "<" },
  { value: 5, text: "<=" },
];

const getRestrictionByType = (type, restrictions) => restrictions?.find(r => r.propertyName === type);
const getOperatorText = (operatorValue, operators) => operators?.find(o => o.value === operatorValue)?.text;
const getOperatorValue = (operatorText, operators) => operators?.find(o => o.text === operatorText)?.value;


const renderRestrictionEdit = ({
  label,
  type,
  inputType,
  onRestrictionChange = () => {},
  clearRestriction = () => {},
  operators = [],
  restrictions = [],
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {label}:
    <MDBSelect
      getTextContent={(val) => onRestrictionChange(type, 'propertyOperator', getOperatorValue(val, operators))}
      className="colorful-select dropdown-primary mx-2"
      style={{ width: 50 }}
    >
      <MDBSelectInput selected={getOperatorText(getRestrictionByType(type, restrictions)?.propertyOperator, operators) || ''} />
      <MDBSelectOptions>
        {operators.map((operator) => (
          <MDBSelectOption key={operator.text} value={operator.text}>
            {operator.text}
          </MDBSelectOption>
        ))}
      </MDBSelectOptions>
    </MDBSelect>
    <input
      value={getRestrictionByType(type, restrictions)?.propertyValue || ''}
      onChange={e => {
        onRestrictionChange(type, 'propertyValue', e.target.value)
      }}
      type={inputType}
      style={{ width: 50, height: 41, border: '1px solid #ced4da', borderRadius: '0.2rem' }}
    />
    <a onClick={() => clearRestriction(type)}>
      <i className='fa fa-times black-text ml-2' />
    </a>
  </div>
)

const CreateWeb = ({ partnerDealId, handleClose, refetch }) => {

  const [finalRestrictions, setFinalRestrictions] = useState([]);

  const [form, setForm] = useState({
    webId: "",
    payout: undefined,
    dailyLimit: undefined,
    isActive: "true",
  });

  const onFormChange = (value, type) => {
    setForm({
      ...form,
      [type]: value,
    })
  };

  const onRestrictionChange = (type, property, value) => {
    const testRestrictions = finalRestrictions;
  
    const restrictionIndex = testRestrictions.findIndex(r => r.propertyName === type);
    if (restrictionIndex > -1) {
      testRestrictions[restrictionIndex][property] = value;
    } else {
      const newRestriction = {
        isNew: true,
        propertyName: type,
        [property]: value,
        objectId: form.webId,
        partnerDealId
      }
      testRestrictions.push(newRestriction);
    }
    setFinalRestrictions([...testRestrictions])
  };

  const onRestrictionClear = (type) => {
    const filtered = finalRestrictions.filter(r => r.propertyName !== type);
    setFinalRestrictions([...filtered]);
  }

  const onSavePress = async () => {
    const isInvalid = Object.values(form).some(v => v === undefined); 
    if (isInvalid) {
      alert('Invalid');
      return;
    }

    if (finalRestrictions.length) {
      finalRestrictions.map(restriction => {
        http.post(`/partnerrestrictions/${partnerDealId}`, {
          objectId: form.webId,
          branch: 3,
          propertyName: restriction.propertyName,
          propertyValue: restriction.propertyValue,
          propertyOperator: restriction.propertyOperator,
        })
      })
    }

    await http.post(`/partnerrestrictions/${partnerDealId}`, {
      objectId: form.webId,
      branch: branch,
      propertyName: "Payout",
      propertyValue: form.payout,
      propertyOperator: 0,
    })

    await http.post(`/partnerrestrictions/${partnerDealId}`, {
      objectId: form.webId,
      branch: branch,
      propertyName: "DailyLimit",
      propertyValue: form.payout,
      propertyOperator: 0,
    })
    refetch();
    handleClose();
  }

  return (
    <tr style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
    <td colSpan={5}>
      <input
        className="form-control form-control-sm"
        value={form.webId}
        style={{ maxWidth: 150 }}
        onChange={(e) => onFormChange(e.target.value, 'webId')}
      />
    </td>
    <td>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          value={form.payout}
          onChange={e => onFormChange(e.target.value, 'payout')}
          style={{ width: 50, height: 41, border: '1px solid #ced4da', borderRadius: '0.2rem' }}
        />
        <a onClick={() => onFormChange(undefined, 'payout')}>
          <i className='fa fa-times black-text ml-2' />
        </a>
      </div>
    </td>
    <td>
      <input
        className="form-control form-control-sm"
        value={form.dailyLimit}
        onChange={(e) => onFormChange(e.target.value, 'dailyLimit')}
      />
    </td>
    <td>
    {renderRestrictionEdit({
      label: "Age",
      type: "age",
      inputType: "number",
      onRestrictionChange: onRestrictionChange,
      clearRestriction: onRestrictionClear,
      operators: ageOperators,
      restrictions: finalRestrictions,
    })}
      {renderRestrictionEdit({
      label: "Geo",
      type: "ipinfo_country",
      inputType: "text",
      onRestrictionChange: onRestrictionChange,
      clearRestriction: onRestrictionClear,
      operators: geoOperators,
      restrictions: finalRestrictions,
      })}
    </td>
    <td>
      <MDBSelect
        getTextContent={e => onFormChange(e, 'isActive')}
        className="colorful-select dropdown-primary mx-2"
      >
        <MDBSelectInput selected={form.isActive} />
        <MDBSelectOptions>
          {["true", "false"].map((param) => (
            <MDBSelectOption key={param} value={param}>
              {param}
            </MDBSelectOption>
          ))}
        </MDBSelectOptions>
      </MDBSelect>
    </td>
    <td>
    <MDBBtn style={{ width: 100 }} color="success" size="sm" onClick={onSavePress}>
      Save
    </MDBBtn>
    <MDBBtn style={{ width: 100 }} color="danger" size="sm" onClick={handleClose}>
      Cancel
    </MDBBtn>
    </td>
    </tr>
  )
}

export default CreateWeb;