/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {   MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBBtn,
  MDBTooltip,
  MDBTableHead,
  MDBIcon } from "mdbreact";
import http from '../../../../services/http';
import CreateSub from './CreateSub';

const geoOperators = [
  { value: 0, text: "=" },
  { value: 1, text: "Not" },
  { value: 6, text: "In list" },
  { value: 7, text: "Not in list" },
];

const ageOperators = [
  { value: 0, text: "=" },
  { value: 2, text: ">" },
  { value: 3, text: ">=" },
  { value: 4, text: "<" },
  { value: 5, text: "<=" },
];

const getOperatorText = (operatorValue, operators) => operators?.find(o => o.value === operatorValue)?.text;
const getOperatorValue = (operatorText, operators) => operators?.find(o => o.text === operatorText)?.value;
const getRestrictionByType = (type, restrictions) => restrictions?.find(r => r.propertyName === type);

const renderPayoutEdit = ({
  type,
  inputType,
  onRestrictionChange = () => {},
  clearRestriction = () => {},
  restrictions = [],
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      value={getRestrictionByType(type, restrictions)?.propertyValue || ''}
      onChange={e => {
        onRestrictionChange(type, 'propertyValue', e.target.value)
        onRestrictionChange(type, 'propertyOperator', 0)

      }}
      type={inputType}
      style={{ width: 50, height: 41, border: '1px solid #ced4da', borderRadius: '0.2rem' }}
    />
    <a onClick={() => clearRestriction(type)}>
      <i className='fa fa-times black-text ml-2' />
    </a>
  </div>
)

const renderStatusEdit = ({
  isActive,
  onChange
}) => {
  return (
    <MDBSelect
    getTextContent={onChange}
    className="colorful-select dropdown-primary mx-2"
  >
    <MDBSelectInput selected={isActive} />
    <MDBSelectOptions>
      {["true", "false"].map((param) => (
        <MDBSelectOption key={param} value={param}>
          {param}
        </MDBSelectOption>
      ))}
    </MDBSelectOptions>
  </MDBSelect>
  )
}

const renderDailyLimitEdit = ({
  type,
  onRestrictionChange = () => {},
  restrictions = [],
}) => {
  const findValue = getRestrictionByType(type, restrictions)?.propertyValue;
  return (
    <input
    className="form-control form-control-sm"
    value={findValue}
    onChange={(e) => onRestrictionChange(type, 'propertyValue', e.target.value)}
  />
  )
}

const renderRestrictionEdit = ({
  label,
  type,
  inputType,
  onRestrictionChange = () => {},
  clearRestriction = () => {},
  operators = [],
  restrictions = [],
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {label}:
    <MDBSelect
      getTextContent={(val) => onRestrictionChange(type, 'propertyOperator', getOperatorValue(val, operators))}
      className="colorful-select dropdown-primary mx-2"
      style={{ width: 50 }}
    >
      <MDBSelectInput selected={getOperatorText(getRestrictionByType(type, restrictions)?.propertyOperator, operators) || ''} />
      <MDBSelectOptions>
        {operators.map((operator) => (
          <MDBSelectOption key={operator.text} value={operator.text}>
            {operator.text}
          </MDBSelectOption>
        ))}
      </MDBSelectOptions>
    </MDBSelect>
    <input
      value={getRestrictionByType(type, restrictions)?.propertyValue || ''}
      onChange={e => {
        onRestrictionChange(type, 'propertyValue', e.target.value)
      }}
      type={inputType}
      style={{ width: 50, height: 41, border: '1px solid #ced4da', borderRadius: '0.2rem' }}
    />
    <a onClick={() => clearRestriction(type)}>
      <i className='fa fa-times black-text ml-2' />
    </a>
  </div>
)

const SubRow = ({ subs, selectedUserId, partnerDealId, operators, webId }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [restrictions, setRestrictions] = useState([]);
  const [finalRestrictions, setFinalRestrictions] = useState([]);
  const [deletedRestrictions, setDeletedRestrictions] = useState([]);

  useEffect(async () => {
    if (selectedUserId) {
      const { data } = await http.get(`/partnerrestrictions/byuser?userId=${selectedUserId}&branch=7`)
      setRestrictions(data);
    }
  }, [selectedUserId])

  const [row, setRow] = useState(null);

  const [filterSubId, setFilterSubId] = useState('');

  const getSubStatus = (sub) => {
    const status = finalRestrictions.find(r => r.objectId === sub.subId && r.partnerDealId === partnerDealId && r?.propertyName === "DailyLimit")?.propertyValue === "-1" ? 'false' : 'true'
    return status;
  }

  useEffect(() => {
    setFinalRestrictions([...restrictions]);
    // setInitialRestrictions([...restrictions]);
  }, [restrictions]);

  // useEffect(() => {
  //   setFinalSubs(subs)
  // }, [subs]);

  // const [openFailureSnackbar] = useSnackbar(failureOptions)


  const onEditClick = (id) => {
   const editSub = subs.find(s => s.id === id);
    setRow({
      ...editSub,
      isActive: getSubStatus(editSub),
    });
  };


  const saveStatus = async () => {
    const { data } = await http.get(`/partnerrestrictions/byuser?userId=${selectedUserId}&branch=7`)
    const dailyLimitRestriction = data.find(r => r.objectId === row?.subId && r.partnerDealId === partnerDealId && r.propertyName === "DailyLimit");

    if (row.isActive === "true" && dailyLimitRestriction && (Number(dailyLimitRestriction.propertyValue) < 0)) {
      await http.delete(`/partnerrestrictions/${partnerDealId}/${dailyLimitRestriction.id}`)
    }
    if (row.isActive === "false" && dailyLimitRestriction) {
      await http.patch(`/partnerrestrictions/${partnerDealId}/${dailyLimitRestriction.id}`, {
        propertyValue: "-1",
        propertyName: "DailyLimit",
        propertyOperator: 0,
      })
    }
    if (row.isActive === "false" && !dailyLimitRestriction) {
      const body = {
        objectId: row?.subId,
        branch: 7,
        propertyName: "DailyLimit",
        propertyValue: "-1",
        propertyOperator: 0,
      }
      await http.post(`/partnerrestrictions/${partnerDealId}`, body);
    }
  }
  const onSavePress = async () => {
    if (deletedRestrictions.length) {
      const deletedPromises = deletedRestrictions.map(deletedRestriction => {
        return http.delete(`/partnerrestrictions/${row?.partnerDealId}/${deletedRestriction.id}`)
      })
      await Promise.all(deletedPromises);
    }
  
    const addedRestrictions = finalRestrictions.filter(r => r.isNew);

    const addPromises = addedRestrictions.map((addedRestriction) => {
      const body = {
        objectId: row?.subId,
        branch: 7,
        propertyName: addedRestriction.propertyName,
        propertyValue: addedRestriction.propertyValue,
        propertyOperator: addedRestriction.propertyOperator,
      }
      return http.post(`/partnerrestrictions/${partnerDealId}`, body);
    })
    await Promise.all(addPromises);


    const changedRestrictions = finalRestrictions.filter(r => r.objectId === row?.subId && !r.isNew);

   const changedPromises = changedRestrictions.map((changedRestriction) => {
      const body = {
        propertyName: changedRestriction.propertyName,
        propertyValue: changedRestriction.propertyValue,
        propertyOperator: changedRestriction.propertyOperator,
      }
      return http.patch(`/partnerrestrictions/${partnerDealId}/${changedRestriction.id}`, body)
    })
    await Promise.all(changedPromises);
  
    await saveStatus();
    setDeletedRestrictions([]);
    setRow(null);
    const { data } = await http.get(`/partnerrestrictions/byuser?userId=${selectedUserId}&branch=7`)
    setRestrictions(data);
    // refetch();
  };

  const onRestrictionChange = (type, property, value) => {
    const testRestrictions = finalRestrictions;
  
    const restrictionIndex = testRestrictions.findIndex(r => r.propertyName === type && r.objectId === row?.subId && r.partnerDealId === partnerDealId);
    if (restrictionIndex > -1) {
      testRestrictions[restrictionIndex][property] = value;
    } else {
      const newRestriction = {
        isNew: true,
        propertyName: type,
        [property]: value,
        objectId: row?.subId,
        partnerDealId: row?.partnerDealId,
      }
      testRestrictions.push(newRestriction);
    }
    setFinalRestrictions([...testRestrictions])
  };

  const onRestrictionClear = (type) => {
    const deleted = finalRestrictions.filter(r => r.propertyName === type && r.objectId === row?.subId);
    const filtered = finalRestrictions.filter(r => r.objectId === row?.subId ? r.propertyName !== type : true);
    setDeletedRestrictions(deleted);
    setFinalRestrictions([...filtered]);
  }

  return (
    <>
      <tr style={{ backgroundColor: '#7d7a7a', color: 'white' }}>
        <td colSpan={5}>

        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
            <span>SubId</span>
            <div style={{ display: 'flex' }}>
            {/* <MDBBtn onClick={() => setIsCreate(true)} style={{ marginRight: 20 }} color="success" size="sm">
              Create Sub
            </MDBBtn> */}
            <input onChange={e => setFilterSubId(e.target.value)} placeholder='Filter subs' />
            </div>
          </div>
        </td>
        <td>Payout</td>
        <td>Limit</td>
        <td>Restrictions</td>
        <td>Is Active</td>
        <td></td>

      </tr>
     {isCreate ? 
      <CreateSub
          handleClose={() => {
            setIsCreate(false);
          }}
          refetch={async () => {
            const { data } = await http.get(`/partnerrestrictions/byuser?userId=${selectedUserId}&branch=7`)
            setRestrictions(data);
          }}
          partnerDealId={partnerDealId}
          webId={webId}
        /> : null}
      {
        subs.filter(sub => sub.subId.includes(filterSubId)).map(sub => {
          if (row?.id === sub?.id) {
            const restrictions = finalRestrictions.filter(r => r.objectId === sub.subId && r.partnerDealId === partnerDealId);
            return (
              <tr style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
              <td colSpan={5}>{sub.subId}</td>
              <td>
              {renderPayoutEdit({
                type: "Payout",
                inputType: "text",
                onRestrictionChange: onRestrictionChange,
                clearRestriction: onRestrictionClear,
                operators: geoOperators,
                restrictions: restrictions,
                })}
              </td>
              <td>
                {renderDailyLimitEdit({
                  type: "DailyLimit",
                  onRestrictionChange,
                  restrictions: restrictions,
                })}
              </td>
              <td>
              {renderRestrictionEdit({
                label: "Age",
                type: "age",
                inputType: "number",
                onRestrictionChange: onRestrictionChange,
                clearRestriction: onRestrictionClear,
                operators: ageOperators,
                restrictions: restrictions,
              })}
                {renderRestrictionEdit({
                label: "Geo",
                type: "ipinfo_country",
                inputType: "text",
                onRestrictionChange: onRestrictionChange,
                clearRestriction: onRestrictionClear,
                operators: geoOperators,
                restrictions: restrictions,
                })}
              </td>
              <td>
              {/* {renderStatusEdit({
                label: "Status",
                type: "IsActive",
                onRestrictionChange: onRestrictionChange,
                restrictions: finalRestrictions,
                })} */}
                {renderStatusEdit({
                  isActive: row.isActive,
                  onChange: (isActive) => setRow({ ...row, isActive })
                })}
              </td>
              <td>
              <MDBBtn style={{ width: 100 }} color="success" size="sm" onClick={onSavePress}>
                Save
              </MDBBtn>
              <MDBBtn style={{ width: 100 }} color="danger" size="sm" onClick={async () => {
                const { data } = await http.get(`/partnerrestrictions/byuser?userId=${selectedUserId}&branch=7`)
                setRestrictions(data);
                setRow(null)
              }}>
                Cancel
              </MDBBtn>
              </td>
              </tr>
            )
          }
          return (
            <tr style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
            <td colSpan={5}>{sub.subId}</td>
            <td>
              {
                finalRestrictions.find(r => r.objectId === sub.subId && r.partnerDealId === partnerDealId && r?.propertyName === "Payout")?.propertyValue || ''
              }
            </td>
            <td>
              {
                finalRestrictions.find(r => r.objectId === sub.subId && r.partnerDealId === partnerDealId && r?.propertyName === "DailyLimit")?.propertyValue || '0'
              }
            </td>
            <td>
              {finalRestrictions.filter(r => r.objectId === sub.subId && r.partnerDealId === partnerDealId && r?.propertyName !== 'Payout' && r.propertyName !== 'PayoutCurrency' && r.propertyName !== "IsActive" && r.propertyName !== "DailyLimit").map(restriction => (
                <div>
                  {restriction?.propertyName === 'ipinfo_country' ? 'geo' : restriction?.propertyName} {operators.find(o => o.value === restriction?.propertyOperator)?.text ?? ''} {restriction?.propertyValue}
                </div>
              ))}
            </td>
            <td>
              {
                finalRestrictions.find(r => r.objectId === sub.subId && r.partnerDealId === partnerDealId && r?.propertyName === "DailyLimit")?.propertyValue === "-1" ? 'false' : 'true'
              }
            </td>
            <td onClick={() => onEditClick(sub.id)}>
              <div>
                <MDBIcon icon="pen" size="1x" />
              </div>
            </td>
          </tr>
          )
        })
      }
    </>
  )
}

export default SubRow;