import React, { useEffect, useState } from 'react';
import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBBtn,
  MDBIcon 
} from "mdbreact";
import { useSnackbar } from 'react-simple-snackbar';
import { failureOptions } from '../../../../configs';
import http from '../../../../services/http';
import SubRow from '../SubRow';
import CreateWeb from './CreateWeb';

const geoOperators = [
  { value: 0, text: "=" },
  { value: 1, text: "Not" },
  { value: 6, text: "In list" },
  { value: 7, text: "Not in list" },
];

const ageOperators = [
  { value: 0, text: "=" },
  { value: 2, text: ">" },
  { value: 3, text: ">=" },
  { value: 4, text: "<" },
  { value: 5, text: "<=" },
];

const getOperatorText = (operatorValue, operators) => operators?.find(o => o.value === operatorValue)?.text;
const getOperatorValue = (operatorText, operators) => operators?.find(o => o.text === operatorText)?.value;
const getRestrictionByType = (type, restrictions) => restrictions?.find(r => r.propertyName === type);

const renderPayoutEdit = ({
  type,
  inputType,
  onRestrictionChange = () => {},
  clearRestriction = () => {},
  restrictions = [],
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      value={getRestrictionByType(type, restrictions)?.propertyValue || ''}
      onChange={e => {
        onRestrictionChange(type, 'propertyValue', e.target.value)
        onRestrictionChange(type, 'propertyOperator', 0)

      }}
      type={inputType}
      style={{ width: 50, height: 41, border: '1px solid #ced4da', borderRadius: '0.2rem' }}
    />
    <a onClick={() => clearRestriction(type)}>
      <i className='fa fa-times black-text ml-2' />
    </a>
  </div>
)

const renderRestrictionEdit = ({
  label,
  type,
  inputType,
  onRestrictionChange = () => {},
  clearRestriction = () => {},
  operators = [],
  restrictions = [],
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {label}:
    <MDBSelect
      getTextContent={(val) => onRestrictionChange(type, 'propertyOperator', getOperatorValue(val, operators))}
      className="colorful-select dropdown-primary mx-2"
      style={{ width: 50 }}
    >
      <MDBSelectInput selected={getOperatorText(getRestrictionByType(type, restrictions)?.propertyOperator, operators) || ''} />
      <MDBSelectOptions>
        {operators.map((operator) => (
          <MDBSelectOption key={operator.text} value={operator.text}>
            {operator.text}
          </MDBSelectOption>
        ))}
      </MDBSelectOptions>
    </MDBSelect>
    <input
      value={getRestrictionByType(type, restrictions)?.propertyValue || ''}
      onChange={e => {
        onRestrictionChange(type, 'propertyValue', e.target.value)
      }}
      type={inputType}
      style={{ width: 50, height: 41, border: '1px solid #ced4da', borderRadius: '0.2rem' }}
    />
    <a onClick={() => clearRestriction(type)}>
      <i className='fa fa-times black-text ml-2' />
    </a>
  </div>
)

const WebRow = ({ webs = [], newRestrictions = [], operators = [], subRestrictions, selectedUserId, partnerDealId, refetch }) => {
  const [subs, setSubs] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [expandedWeb, setExpandedWeb] = useState(null);

  const [initialRestrictions, setInitialRestrictions] = useState([]);

  const [row, setRow] = useState(null);

  const [filterWebId, setFilterWebId] = useState('');

  const [finalWebs, setFinalWebs] = useState([]);

  const [finalRestrictions, setFinalRestrictions] = useState([]);
  const [deletedRestrictions, setDeletedRestrictions] = useState([]);

  useEffect(() => {
    setFinalRestrictions([...newRestrictions]);
    setInitialRestrictions([...newRestrictions]);
  }, [newRestrictions]);

  useEffect(() => {
    setFinalWebs(webs)
  }, [webs]);

  const [openFailureSnackbar] = useSnackbar(failureOptions)


  const onEditClick = (id) => {
   const editWeb = finalWebs.find(w => w.id === id);
    setRow(editWeb);

  };

  const onFieldChange = (value, innerProp) => {
    setRow({
      ...row,
      [innerProp]: value,
    });
  };

  const getRowStatus = (row) => {
    if (row.status === "false") {
      return 0
    }
    if (row.status === "true") {
      return 1
    }
    return row.status;
  } 


  const onSavePress = async () => {
    try {
      await http.patch(`/partnerdeals/${row.partnerDealId}/webs/${row.id}`, {
        dailyLimit: row.dailyLimit,
        status: getRowStatus(row),
      })
      const changedWebIndex = finalWebs.findIndex(web => web.id === row.id);

      if (changedWebIndex > -1) {
        finalWebs[changedWebIndex] = row;
      }
      setFinalWebs([
        ...finalWebs,
      ])
    } catch (err) {

    }

    if (deletedRestrictions.length) {
      const deletedPromises = deletedRestrictions.map(deletedRestriction => {
        return http.delete(`/partnerrestrictions/${row?.partnerDealId}/${deletedRestriction.id}`)
      })
      await Promise.all(deletedPromises);
    }
  
    const addedRestrictions = finalRestrictions.filter(r => r.isNew);

    const addPromises = addedRestrictions.map((addedRestriction) => {
      const body = {
        objectId: row?.webId,
        branch: 3,
        propertyName: addedRestriction.propertyName,
        propertyValue: addedRestriction.propertyValue,
        propertyOperator: addedRestriction.propertyOperator,
      }
      return http.post(`/partnerrestrictions/${row?.partnerDealId}`, body);
    })
    await Promise.all(addPromises);


    const changedRestrictions = finalRestrictions.filter(r => r.objectId === row?.webId && !r.isNew);

   const changedPromises =  changedRestrictions.map((changedRestriction) => {
      const body = {
        // objectId: webs[isEditable]?.webId,
        // branch: 3,
        propertyName: changedRestriction.propertyName,
        propertyValue: changedRestriction.propertyValue,
        propertyOperator: changedRestriction.propertyOperator,
      }
      return http.patch(`/partnerrestrictions/${row?.partnerDealId}/${changedRestriction.id}`, body)
    })
    await Promise.all(changedPromises);
  
    setDeletedRestrictions([]);
    setRow(null);
    // refetch();
  };

  const onRestrictionChange = (type, property, value) => {
    const testRestrictions = [...finalRestrictions];
  
    const restrictionIndex = testRestrictions.findIndex(r => r.propertyName === type && r.objectId === row?.webId && r.partnerDealId === row?.partnerDealId);
    if (restrictionIndex > -1) {
      testRestrictions[restrictionIndex][property] = value;
    } else {
      const newRestriction = {
        isNew: true,
        propertyName: type,
        [property]: value,
        objectId: row?.webId,
        partnerDealId: row?.partnerDealId,
      }
      testRestrictions.push(newRestriction);
    }
    setFinalRestrictions([...testRestrictions])
  };

  const onRestrictionClear = (type) => {
    const deleted = finalRestrictions.filter(r => r.propertyName === type && r.objectId === row?.webId);
    const filtered = finalRestrictions.filter(r => r.objectId === row?.webId ? r.propertyName !== type : true);
    setDeletedRestrictions(deleted);
    setFinalRestrictions([...filtered]);
  }

  const handleOpenSub = async (web) => {
    if (expandedWeb?.id !== web.id) {
      setSubs([]);
      setExpandedWeb(web);
      const { data } = await http.get(`/partnerdeals/${web.partnerDealId}/webs/${web.id}/subs`)
      setSubs(data);
      return;
    }
    if (expandedWeb) {
      setExpandedWeb(null)
      setSubs([]);
    } else {
      setExpandedWeb(web);
      const { data } = await http.get(`/partnerdeals/${web.partnerDealId}/webs/${web.id}/subs`)
      setSubs(data);
    }
  }
  return (
    <>
      <tr style={{ backgroundColor: '#454545', color: 'white' }}>
        <td colSpan={5}>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
            <span>WebId</span>
            <div style={{ display: 'flex' }}>
            {/* <MDBBtn onClick={() => setIsCreate(true)} style={{ marginRight: 20 }} color="success" size="sm">
              Create Web
            </MDBBtn> */}
            <input placeholder='Filter webs' value={filterWebId} onChange={e => setFilterWebId(e.target.value)} />
            </div>
          </div>
        </td>
        <td>Payout</td>
        <td>Limit</td>
        <td>Restrictions</td>
        <td>Is Active</td>
        <td></td>

      </tr>
     {isCreate ? <CreateWeb handleClose={() => setIsCreate(false)} partnerDealId={partnerDealId} refetch={refetch} /> : null}

      {finalWebs.filter(web => web.webId.includes(filterWebId)).map((web, index) => {
        const restrictions = finalRestrictions.filter(r => r.objectId === web.webId && r.partnerDealId === web.partnerDealId);
        if (web.id === row?.id) {
          return (
            <tr style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
            <td colSpan={5}>{web.webId}</td>
            <td>
            {renderPayoutEdit({
              type: "Payout",
              inputType: "text",
              onRestrictionChange: onRestrictionChange,
              clearRestriction: onRestrictionClear,
              operators: geoOperators,
              restrictions,
              })}
            </td>
            <td>
            <input
              className="form-control form-control-sm"
              value={row.dailyLimit}
              onChange={(e) => onFieldChange(e.target.value, "dailyLimit")}
            />
            </td>
            <td>
            {renderRestrictionEdit({
              label: "Age",
              type: "age",
              inputType: "number",
              onRestrictionChange: onRestrictionChange,
              clearRestriction: onRestrictionClear,
              operators: ageOperators,
              restrictions,
            })}
              {renderRestrictionEdit({
              label: "Geo",
              type: "ipinfo_country",
              inputType: "text",
              onRestrictionChange: onRestrictionChange,
              clearRestriction: onRestrictionClear,
              operators: geoOperators,
              restrictions,
              })}
            </td>
            <td>
            <MDBSelect
              className="colorful-select dropdown-primary mx-2"
              getTextContent={(val) => onFieldChange(val, "status")}
            >
              <MDBSelectInput selected={`${web.status === 1}`} />
              <MDBSelectOptions>
                {[{ name: "true" }, { name: "false" }].map((action) => (
                  <MDBSelectOption key={action.id} value={action.name}>
                    {action.name}
                  </MDBSelectOption>
                ))}
              </MDBSelectOptions>
            </MDBSelect>
            </td>
            <td>
            <MDBBtn style={{ width: 100 }} color="success" size="sm" onClick={onSavePress}>
              Save
            </MDBBtn>
            <MDBBtn style={{ width: 100 }} color="danger" size="sm" onClick={() => {
              setFinalRestrictions([...initialRestrictions]);
              setRow(null)
            }}>
              Cancel
            </MDBBtn>
            </td>
            </tr>
          )
        }
        return (
          <React.Fragment key={web.id}>
          <tr key={web.id} style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
            <td colSpan={5}>
            <MDBIcon onClick={() => handleOpenSub(web)} icon={(expandedWeb?.id === web.id) ? 'angle-up' : 'angle-down'} color='black' size='lg' />
              {web.webId}
              </td>
            <td>
              {
                finalRestrictions.find(r => r.objectId === web.webId && r.partnerDealId === web.partnerDealId && r?.propertyName === "Payout")?.propertyValue || ''
              }
            </td>
            <td>{web.dailyLimit}</td>
            <td>
              {finalRestrictions.filter(r => r.objectId === web.webId && r.partnerDealId === web.partnerDealId && r?.propertyName !== 'Payout' && r.propertyName !== 'PayoutCurrency').map(restriction => (
                <div>
                  {restriction?.propertyName === 'ipinfo_country' ? 'geo' : restriction?.propertyName} {operators.find(o => o.value === restriction?.propertyOperator)?.text ?? ''} {restriction?.propertyValue}
                </div>
              ))}
            </td>
            <td>{web.status === 1 || web.status === 'true' ? 'true' : 'false'}</td>
            <td onClick={() => onEditClick(web.id)}>
              <div>
                <MDBIcon icon="pen" size="1x" />
              </div>
            </td>

          </tr>
          {expandedWeb?.id === web.id ? <SubRow operators={operators} subs={subs} selectedUserId={selectedUserId} partnerDealId={web.partnerDealId} webId={web.webId} /> : null}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default WebRow;