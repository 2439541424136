import React, { useEffect, useState } from "react";
import {
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCard,
  MDBSelect,
  MDBCol,
  MDBSpinner,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBIcon
} from "mdbreact";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { useSelector } from 'react-redux';

import http from "../../../../services/http";

import * as S from "./styled";
import TableRow from "../TableRow";
import AddRow from "../AddRow";
import { getRoleFromToken } from "../../../../helpers";

const PartnerDealsTable = ({ choosedSiteId, user }) => {

  const location = useLocation();

  const userEmail = useSelector(state => state.auth.user.email);

  const queryParams = new URLSearchParams(location.search);

  const [operators, setOperators] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [info, setInfo] = useState([]);
  const [isAdding, setAdding] = useState(false);
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [landings, setLandings] = useState([]);
  const [restrisctions, setRestrictions] = useState([]);

  const resize = () => {
    if (window.innerWidth >= 780 && collapsed) {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    async function fetchOperators() {
      const { data } = await http.get('/partnerdeals/propertyoperator');
      const operatorsMock = [
        {value: 0, text: '='},
        {value: 1, text: 'Not'},
        {value: 2, text: '>'},
        {value: 3, text: '>='},
        {value: 4, text: '<'},
        {value: 5, text: '<='},
        {value: 6, text: 'In list'},
        {value: 7, text: 'Not in list'},

      ];
      setOperators(operatorsMock)
      // setOperators(data);
    }
    fetchOperators();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, []);

  const [filter, setFilter] = useState({
    linkId: "",
    linkName: queryParams.get("linkName") || "",
    payOut: {
      operation: {
        value: "=",
        label: "=",
      },
      value: "",
    },
    isActive: {
      label: "True",
      value: "True",
    },
  });

  const [sorting, setSorting] = React.useState({
    field: "",
    direction: "asc",
  });

  const [loadingInfo, setLoadingInfo] = useState(false);

  const [newRestrictions, setNewRestrictions] = useState([]);
  const [restrictionsSub, setRestrictionsSub] = useState([]);


  useEffect(() => {
    if (selectedPartner) {
      getInfo();
    }
  }, [choosedSiteId, selectedPartner]);

  useEffect(() => {
    getPartners();
  }, [choosedSiteId]);

  useEffect(() => {
    getLandings();
  }, [choosedSiteId]);

  useEffect(() => {
    getActions();
  }, [])
  const [actions, setActions] = useState([]);

  const getActions = async () => {
    const { data } = await http.get("/partnerdeals/actiontypes");
    const { data: test } = await http.get("/partnerrestrictions/rules");

    console.log('test', test);

    setActions(data.filter(a => a.id !== 5));
  };

  const getWebs = async (deals) => {
    const promises = deals.map(deal => http.get(`/partnerdeals/${deal.id}/webs`));
    const resolved = await Promise.allSettled(promises);
    const webs = resolved.filter(i => i.status === 'fulfilled').map(i => i.value.data);

    const final = deals.map((deal, index) => {
      return ({
        ...deal,
        webs: webs[index],
      })
    })
    return final;
  }

  const getPartners = async () => {
    const { data } = await http.get("/users");
    const formatted = data
      // .filter(i => i.roles?.length &&  i.roles?.[0]?.role === 3 && i.roles.some(role => (role.siteId === null || role.siteId === choosedSiteId)))
      .filter((i) => i.roles?.length && i.roles?.[0]?.role === 3)
      // .filter(i => i.roles?.length && i.roles.some(role => role.siteId === null))
      // .filter(i => i.roles?.length && i.roles.some(role => role.siteId === choosedSiteId || role.siteId === null))
      .map((i) => ({
        ...i,
        text: i.email,
        value: i.email,
        role: 3,
      }))
      .sort((a, b) => a.email.localeCompare(b.email));
    const indexToCheck =
      formatted.findIndex((i) => {
        if (userEmail === "dasha@mediamars.com") {
          return i.email === "adverten@mediamars.com"
        }
        if (userEmail === "ishai@mediamars.com") {
          return i.email === "oksana@schrodingersteam.com"
        }
        return i.email === "cpamatica@mediamars.com"
        // return  i.email === "maria.kasvina@gmail.com"
      }) || 2;
    if (formatted[indexToCheck]) {
      formatted[indexToCheck].checked = true;
    }

    setPartners(formatted);
    if (formatted[indexToCheck]) {
      setSelectedPartner(formatted[indexToCheck].email);
    }
  };

  const getLandings = async () => {
    const { data: landingData } = await http.get(
      `/sites/${choosedSiteId}/landings`
    );
    setLandings(landingData);
  };

  const selectedUserId = partners.find((p) => p.email === selectedPartner)?.id || null;

  const getInfo = async () => {
    setLoadingInfo(true);
    const userId = partners.find((p) => p.email === selectedPartner)?.id;
    try {
      const { data } = await http.get("/partnerdeals/byuserdetailed", {
        params: {
          userId: userId,
        },
      });

      const { data: webRestrictions } = await http.get("/partnerrestrictions/byuser", {
        params: {
          userId: userId,
          branch: 3,
          exclude: 'DailyLimit'
        },
      });

      const { data: subRestrictions } = await http.get("/partnerrestrictions/byuser", {
        params: {
          userId: userId,
          branch: 7,
          // exclude: 'DailyLimit'
        },
      });

      setNewRestrictions(webRestrictions);

      setRestrictionsSub(subRestrictions);

      const filteredDealsBySiteId = data.filter((i) => i.siteId === choosedSiteId);
      const webs = await getWebs(filteredDealsBySiteId);

      setInfo(webs);
    } catch (err) {
      alert('Errored');
    }
    setLoadingInfo(false);
  };

  const onSelectPartner = (partner) => {
    setSelectedPartner(partner);
    const formatted = partners;
    const index = formatted.findIndex((i) => i.email === partner);
    if (formatted[index]) {
      formatted[index].checked = true;
    }
    setPartners(formatted);
  };

  const onFilterChange = (type, value) =>
    setFilter({ ...filter, [type]: value });

  const onPayoutValueChange = (value) => {
    setFilter({
      ...filter,
      payOut: {
        ...filter.payOut,
        value,
      },
    });
  };

  const onPayoutOperationChange = (operation) => {
    setFilter({
      ...filter,
      payOut: {
        ...filter.payOut,
        operation,
      },
    });
  };

  const filterInfo = (data) => {
    return data
      .filter((item) => item.partnerLink.uid.includes(filter.linkId))
      .filter((item) =>
        item.partnerLink.name
          .toLowerCase()
          .includes(filter.linkName.toLowerCase())
      )
      .filter((item) => {
        if (filter.isActive.value === "True") {
          return item.isActive;
        }
        if (filter.isActive.value === "False") {
          return !item.isActive;
        }
        return true;
      })
      .filter((item) => {
        if (filter.payOut.value === "") {
          return true;
        }
        if (filter.payOut.operation.value === "=") {
          return item.payout == filter.payOut.value;
        }
        if (filter.payOut.operation.value === ">=") {
          return item.payout >= filter.payOut.value;
        }
        if (filter.payOut.operation.value === "<=") {
          return item.payout <= filter.payOut.value;
        }
        if (filter.payOut.operation.value === ">") {
          return item.payout > filter.payOut.value;
        }
        if (filter.payOut.operation.value === "<") {
          return item.payout < filter.payOut.value;
        }
        return true;
      });
  };

  const onSortClick = (header) => {
    setSorting({
      field: header,
      direction: sorting.direction === "asc" ? "desc" : "asc",
    });
  };

  const isString = (value) => typeof value === "string";

  const sortedData = () => {
    if (!info.length) {
      return [];
    }

    if (sorting.field === "LinkId") {
      return info.sort((a, b) => {
        const aValue = Number(a.partnerLink.uid);
        const bValue = Number(b.partnerLink.uid);
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
    }

    if (sorting.field === "LinkName") {
      return info.sort((a, b) => {
        const aValue = a.partnerLink.name;
        const bValue = b.partnerLink.name;
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
    }
    if (sorting.field === "Payout") {
      return info.sort((a, b) => {
        const aValue = Number(a.payout);
        const bValue = Number(b.payout);
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
    }
    if (sorting.field === "IsActive") {
      return info.sort((a, b) => {
        const aValue = String(a.isActive);
        const bValue = String(b.isActive);
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
    }
    return info;
  };

  const [copyRow, setCopyRow] = useState(null);

  const onCopyClick = (row) => {
    setAdding(true);
    setCopyRow(row);
  };

  const resetCopyRow = () => {
    setCopyRow(null);
  }
  return (
    <MDBCard narrow className="pb-3">
      <MDBCardBody>
      <S.CollapseButton onClick={() => setCollapsed(!collapsed)}>
        <MDBIcon icon={!collapsed ? 'minus' : 'bars'} />
      </S.CollapseButton>
        {!collapsed && <>
          <MDBRow>
            <MDBCol lg="3" md="6">
              <MDBSelect
                label="Partner"
                className="colorful-select dropdown-primary mx-2"
                options={partners}
                selected={selectedPartner}
                getTextContent={onSelectPartner}
                search
                disabled={loadingInfo}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="3">
              <MDBInput
                label="LinkId"
                value={filter.linkId}
                onChange={(e) => onFilterChange("linkId", e.target.value)}
              />
            </MDBCol>
            <MDBCol md="3">
              <MDBInput
                label="LinkName"
                value={filter.linkName}
                onChange={(e) => onFilterChange("linkName", e.target.value)}
              />
            </MDBCol>
            <MDBCol md="3">
              <span>Is Active</span>
              <Select
                isSearchable={false}
                styles={{ control: (s) => ({ ...s, marginTop: 5 }) }}
                placeholder={"Is Active"}
                options={[
                  { value: "False", label: "False" },
                  { value: "True", label: "True" },
                  { value: "No Filter", label: "No Filter" },
                ]}
                value={filter.isActive}
                onChange={(val) => onFilterChange("isActive", val)}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="2">
              <span>Payout compare</span>
              <Select
                isSearchable={false}
                styles={{ control: (s) => ({ ...s, marginTop: 5 }) }}
                placeholder={"operation"}
                options={[
                  { value: "=", label: "=" },
                  { value: ">", label: ">" },
                  { value: ">=", label: ">=" },
                  { value: "<", label: "<" },
                  { value: "<=", label: "<=" },
                ]}
                value={filter.payOut.operation}
                onChange={(val) => onPayoutOperationChange(val)}
              />
            </MDBCol>
            <MDBCol md="3">
              <MDBInput
                label="Payout"
                value={filter.payOut.value}
                onChange={(e) => onPayoutValueChange(e.target.value)}
              />
            </MDBCol>
          </MDBRow>
        </>}
        <S.Container>
          <MDBTable
            scrollY
            responsive
            bordered
            maxHeight={500}
          >
            <MDBTableHead color="cyan" textWhite>
              <tr>
                <S.Th
                  direction={sorting.direction}
                  isSorting={sorting.field === "LinkId"}
                  onClick={() => onSortClick("LinkId")}
                >
                  LinkId
                </S.Th>
                <S.Th
                  direction={sorting.direction}
                  isSorting={sorting.field === "LinkName"}
                  onClick={() => onSortClick("LinkName")}
                >
                  LinkName
                </S.Th>
                <th>ActionType</th>
                {/* <th>Landing</th> */}
                <th>Afflink</th>
                <th>Postback</th>
                <S.Th
                  direction={sorting.direction}
                  isSorting={sorting.field === "Payout"}
                  onClick={() => onSortClick("Payout")}
                >
                  Payout
                </S.Th>
                <th>Limit</th>
                <th>Restrictions</th>
                {/* <th>Currency</th> */}
                <S.Th
                  direction={sorting.direction}
                  isSorting={sorting.field === "IsActive"}
                  onClick={() => onSortClick("IsActive")}
                >
                  IsActive
                </S.Th>
                <th></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <td style={{ padding: 0 }} colSpan={12}>
                  {!isAdding && (
                    <S.AddButton
                      disabled={loadingInfo}
                      onClick={() => setAdding(true)}
                    >
                      <S.AddText>ADD+</S.AddText>
                    </S.AddButton>
                  )}
                </td>
              </tr>
              {isAdding && (
                <AddRow
                  landings={landings}
                  userId={partners.find((p) => p.email === selectedPartner).id}
                  getInfo={getInfo}
                  choosedSiteId={choosedSiteId}
                  setAdding={setAdding}
                  operators={operators}
                  rows={filterInfo(sortedData())}
                  copyRow={copyRow}
                  resetCopyRow={resetCopyRow}
                  actions={actions}
                />
              )}
              {!loadingInfo ? (
                filterInfo(sortedData()).map((row) => (
                  <TableRow
                    key={row.id}
                    landings={landings}
                    row={row}
                    getInfo={getInfo}
                    operators={operators}
                    onCopyClick={onCopyClick}
                    actions={actions}
                    newRestrictions={newRestrictions}
                    subRestrictions={restrictionsSub}
                    selectedUserId={selectedUserId}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={12}>
                    <MDBSpinner />
                  </td>
                </tr>
              )}
            </MDBTableBody>
          </MDBTable>
        </S.Container>
      </MDBCardBody>
    </MDBCard>
  );
};

const mapStateToProps = ({ app, auth }) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(PartnerDealsTable);
