/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import qs from "querystring";
import { useSnackbar } from "react-simple-snackbar";
import {
  MDBCard,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCardBody,
  MDBBtn,
  MDBTable,
  MDBSpinner,
  MDBIcon,
} from "mdbreact";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Line, Bar } from "react-chartjs-2";
import {
  groupParams as tabs,
  graphOptionsTypes,
  TABLE_HEADERS,
  selectOptions,
} from "./configs";
import { LINE_GRAPH_OPTIONS, options } from "./graphOptions";
import _ from "lodash";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

import CacheStorage from "../../../../services/storage/cache";

import * as S from "./styled";

import http from "../../../../services/http";
import { failureOptions } from "../../../../configs";
import { useLocation } from "react-router-dom";
import DateInput from "../../../../components/DateInput";
import useDateScrollHeader from "../../../../hooks/useDateScrollHeader";
import SettingsModal from "./SettingsModal";

const dataLine = {
  labels: [],
  datasets: [
    {
      ...LINE_GRAPH_OPTIONS,
      label: "Fill profiles",
      backgroundColor: "rgba(0, 0, 0, 0)",
      borderColor: "#1266f1",
      data: [],
    },
  ],
};

const dataBar = {
  labels: [],
  datasets: [
    {
      ...LINE_GRAPH_OPTIONS,
      label: "Fill profiles",
      backgroundColor: "#1266f1",
      borderColor: "#1266f1",
      data: [],
    },
  ],
};

const ageOptions = [
  {
    value: "r2",
    label: "35+",
  },
  {
    value: "r1",
    label: "34-",
  },
];

const osOptions = [
  { label: "Android", value: "Android" },
  { label: "iOS", value: "iOS" },
  { label: "Linux", value: "Linux" },
  { label: "Windows", value: "Windows" },
  { label: "Other", value: "Other" },
];

const Statistics = () => {
  const location = useLocation();

  const initialSettingsConfig = localStorage.getItem('settings-config') ? JSON.parse(localStorage.getItem('settings-config')) : {
    columns: [
      'emailConfirmed',
      'fpSu',
      'fp4s',
      'fpFtd',
      'online',
    ],
    groups: [
      'utm_content'
    ],
    filters: [
      'utm_content'
    ],
  }

  const [settingsConfig, setSettingsConfig] = useState(initialSettingsConfig);

  const [partnerOptions, setPartnerOptions] = useState([]);
  const [isSettingsVisible, setSettingsVisible] = useState(false);

  const [isHided, setIsHided] = useState(true);

  const queryParams = new URLSearchParams(location.search);
  const choosedSiteId = useSelector((state) => state.app.choosedSiteId);

  const groupParams = useMemo(() => {
    // if (choosedSiteId == 15) {
    //   return [...tabs].filter(t => t.id !== 'web_id' && t.id !== 'linkid' && t.id !== 'sub_id')
    // }
    return tabs.filter((t) => t.id !== "userrole");
  }, [choosedSiteId]);

  const [openFailureSnackbar] = useSnackbar(failureOptions);

  useEffect(async () => {
    const { data } = await http.get('/reports/main_partners');
    const formatted = data.map(item => ({ value: item.value, label: item.text }));
    setPartnerOptions(formatted);
  }, []);


  const [sorting, setSorting] = React.useState({
    field: "grouping",
    direction: "desc",
  });

  const [isToggled] = useState(true);

  const [isEmpty, setEmpty] = useState(true);
  const [filterForm, setFilterForm] = useState({
    age: "",
    day: "",
    ipinfo_country: "",
    linkid:
      queryParams.get("filterId") === "linkid"
        ? queryParams.get("grouping")
        : queryParams.get("linkid"),
    month: "",
    sub_id: "",
    userrole: "",
    utm_campaign:
      queryParams.get("filterId") === "utm_campaign"
        ? queryParams.get("grouping")
        : "",
    utm_source:
      queryParams.get("filterId") === "utm_source"
        ? queryParams.get("grouping")
        : "",
    web_id:
      queryParams.get("filterId") === "web_id"
        ? queryParams.get("grouping")
        : "",
  });

  const [dateForm, setDateForm] = useState({
    from: queryParams.get("fromDate")
      ? queryParams.get("fromDate")
      : moment().startOf("month").format("YYYY-MM-DD"),
    to: queryParams.get("toDate")
      ? queryParams.get("toDate")
      : moment().format("YYYY-MM-DD"),
  });

  const [data, setData] = useState([]);
  const [activeGroupParam, setActiveGroupParam] = useState(
    queryParams.get("groupParam") || "day"
  );
  const [loading, setLoading] = useState(false);

  const [selectedChartType, setSelectedChartType] = useState("fillProfiles");

  useEffect(() => {
    if (activeGroupParam === "day" || activeGroupParam === "month") {
      setSorting({
        ...sorting,
        direction: "desc",
      });
    } else {
      setSorting({
        ...sorting,
        direction: "asc",
      });
    }
  }, [activeGroupParam]);

  useEffect(() => {
    const groupParam = queryParams.get("groupParam");
    const filterId = queryParams.get("filterId");
    const grouping = queryParams.get("grouping");
    const fromDate = queryParams.get("fromDate");
    const toDate = queryParams.get("toDate");

    if (fromDate && toDate) {
      setDateForm({
        to: toDate,
        from: fromDate,
      });
    }

    if (groupParam) {
      setActiveGroupParam(groupParam);
    }
    if (choosedSiteId !== 15) {
      setFilterForm({
        ...filterForm,
        userrole: "",
      });
    }
    if (filterId && grouping) {
      setFilterForm({
        ...filterForm,
        [filterId]: grouping,
      });
    }
  }, [location.search, choosedSiteId]);

  const countSum = (field) => {
    return _.sumBy(data, field);
  };

  const onSortClick = (header) => {
    if (header === "ID") return;
    setSorting({
      field: header,
      direction: sorting.direction === "asc" ? "desc" : "asc",
    });
  };

  const isString = (value) => typeof value === "string";

  const sortByDate = (data, isReverse = true) => {
    if (!data.length) {
      return [];
    }
    const field = "grouping";
    return data.sort((a, b) => {
      const aValue = isString(a[field])
        ? a[field].includes("$")
          ? Number(a[field].replace(/\$/g, ""))
          : a[field]
        : a[field];
      const bValue = isString(b[field])
        ? b[field].includes("$")
          ? Number(b[field].replace(/\$/g, ""))
          : b[field]
        : b[field];

      return !isReverse ? (aValue > bValue ? -1 : 1) : aValue < bValue ? -1 : 1;
    });
  };

  console.log('activeGroupParam', activeGroupParam)
  const sortedData = (data) => {
    if (!data.length) {
      return [];
    }

    const field = sorting.field;

    return data.sort((a, b) => {
      if (activeGroupParam === "month") {
        const aValue = Number(moment(a[field], "YYYY-M").format("M"));
        const bValue = Number(moment(b[field], "YYYY-M").format("M"));
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      }
      if (field === "online") {
        const aValue = Math.floor(a.online / a.fillProfiles);
        const bValue = Math.floor(b.online / b.fillProfiles);
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      }
      const aValue = isString(a[field])
        ? a[field].includes("$")
          ? Number(a[field].replace(/\$/g, ""))
          : a[field].toLowerCase()
        : a[field];
      const bValue = isString(b[field])
        ? b[field].includes("$")
          ? Number(b[field].replace(/\$/g, ""))
          : b[field].toLowerCase()
        : b[field];

      return sorting.direction === "asc"
        ? aValue < bValue
          ? -1
          : 1
        : aValue > bValue
        ? -1
        : 1;
    });
  };

  const urlString = useMemo(() => {
    let url = isToggled ? "/reports/main2?" : "/reports/main?";
    url += `SiteId=${choosedSiteId}`;
    url += `&StartDate=${dateForm.from}&EndDate=${dateForm.to}`;
    if (isToggled) {
      url += `&Grouping=${tabs.find((i) => i.id === activeGroupParam).main2}`;
    } else {
      url += `&grouping[${
        tabs.find((i) => i.id === activeGroupParam).group
      }]=${activeGroupParam}`;
    }
    Object.keys(filterForm).forEach((key) => {
      if (!isToggled) {
        if (filterForm[key] && key !== "userrole") {
          url += `&filter[${tabs.find((i) => i.id === key).group}:${key}]=${
            filterForm[key]
          }`;
        }
        if (
          key === "userrole" &&
          filterForm.userrole.length !== 2 &&
          filterForm.userrole.length !== 0
        ) {
          url += `&filter[up:userrole]=${filterForm.userrole[0].value}`;
        }
      } else {
        if (filterForm[key] && key !== "userrole") {
          url += `&${tabs.find((i) => i.id === key).main2}=${encodeURIComponent(filterForm[key])}`;
        }
        if (
          key === "userrole" &&
          filterForm.userrole.length !== 2 &&
          filterForm.userrole.length !== 0
        ) {
          url += `&filter[up:userrole]=${filterForm.userrole[0].value}`;
        }
      }
    });
    return url;
  }, [choosedSiteId, filterForm, activeGroupParam, dateForm, isToggled]);

  const formatDateMonth = (item, withoutDayFormat = false) => {
    if (activeGroupParam === "day" && !withoutDayFormat) {
      return moment(item.grouping, "YYYY-MM-DD").format("DD.MM");
    }
    if (activeGroupParam === "month") {
      return moment(item.grouping, "YYYY-M").format("MM.YY");
    }
    return item.grouping;
  };

  const lineChartData = useMemo(
    () => ({
      ...dataLine,
      labels: data.map((i) => formatDateMonth(i)),
      datasets: [
        {
          ...dataLine.datasets[0],
          data: data.map((i) => i[selectedChartType]),
          label: selectedChartType,
        },
      ],
    }),
    [selectedChartType, data]
  );

  const barChartData = useMemo(
    () => ({
      ...dataBar,
      labels: data.map((i) => i.grouping),
      datasets: [
        {
          ...dataBar.datasets[0],
          data: data.map((i) => i[selectedChartType]),
          label: selectedChartType,
        },
      ],
    }),
    [selectedChartType, data]
  );

  const loadData = async () => {
    setEmpty(false);
    setLoading(true);
    try {
      const { data: response } = await http.get(`${urlString}`);
      if (activeGroupParam === "day" || activeGroupParam === "month") {
        setData(sortByDate(response));
      } else {
        setData(response);
      }
    } catch (err) {
      openFailureSnackbar("Something went wrong :(");
      setEmpty(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useDateScrollHeader(
    {
      date: `${moment(dateForm.from).format("DD-MM-YYYY")} - ${moment(
        dateForm.to
      ).format("DD-MM-YYYY")}`,
    },
    [dateForm]
  );

  const renderSelect = (label, id) => (
    <MDBCol lg="3" md="6">
      <Select
        styles={{ control: (s) => ({ ...s, marginTop: 25 }) }}
        placeholder={label}
        options={selectOptions}
        isMulti
        onChange={(val) =>
          setFilterForm({
            ...filterForm,
            [id]: val,
          })
        }
      />
    </MDBCol>
  );

  const renderDropdown = (label, id, options) => {
    const filtersConfig = settingsConfig.filters;

    if (filtersConfig.includes(id)) return null;
    return (
      <MDBCol lg="3" md="6">
        <Select
          isSearchable={false}
          styles={{ control: (s) => ({ ...s, marginTop: 25 }) }}
          placeholder={label}
          options={options}
          isClearable
          onChange={(val) =>
            setFilterForm({
              ...filterForm,
              [id]: val?.value || "",
            })
          }
        />
      </MDBCol>
    );
  };

  const renderInput = (label, id) => {
    const filtersConfig = settingsConfig.filters;

    if (filtersConfig.includes(id)) return null;
    return (
      <MDBCol lg="3" md="6">
        <MDBInput
          required
          label={label}
          type="text"
          group
          value={filterForm[id]}
          onChange={(e) =>
            setFilterForm({
              ...filterForm,
              [id]: e.target.value,
            })
          }
        />
      </MDBCol>
    );
  };

  const generateCsv = (data) => {
    const tableConfig = settingsConfig.columns;
    const results = [];
    data.forEach((i) => {
      results.push([
        i.grouping,
        !tableConfig.includes("fpSu") ? `${Math.round((i.signUps / i.fillProfiles) * 100)}%` : undefined,
        (choosedSiteId === 37 || choosedSiteId === 69) && !tableConfig.includes("fp4s") ? `${Math.round((i.sms4Plus35 / i.fillProfiles) * 100)}%` : undefined,
        !tableConfig.includes("fpFtd") ? `${Math.round((i.ftd / i.fillProfiles) * 100)}%` : undefined,
        !tableConfig.includes("registrations") ? i.registrations : undefined,
        !tableConfig.includes("fillProfiles") ? i.fillProfiles : undefined,
        !tableConfig.includes("emailConfirmed") ? i.emailConfirmed : undefined,
        !tableConfig.includes("signUps") ? i.signUps : undefined,
        (choosedSiteId === 37 || choosedSiteId === 69) && !tableConfig.includes("sms4Plus35") ? i.sms4Plus35 : undefined,
        !tableConfig.includes("online") ? `${Math.floor(i.online / i.fillProfiles)}` : undefined,
        !tableConfig.includes("sales") ? i.sales : undefined,
        !tableConfig.includes("ftd") ? i.ftd : undefined,
        !tableConfig.includes("coins") ? i.coins : undefined,
        !tableConfig.includes("money") ? i.money : undefined,
      ]);
    });

    const headers = TABLE_HEADERS.map((h) => {
      if (!h.siteIds || h.siteIds.includes(choosedSiteId)) {
        if (tableConfig.includes(h.id)) return null;
        return h.header
      }
      return null;
    }).filter(h => h !== null);

    const finalResults = results.map(i => i.filter(r => r !== undefined))
  
    const total = [
      "Total",
      !tableConfig.includes("fpSu") ? `${Math.round((countSum("signUps") / countSum("fillProfiles")) * 100)}%` : undefined,
      (choosedSiteId === 37 || choosedSiteId === 69) && !tableConfig.includes("fp4s") ? `${Math.round((countSum("sms4Plus35") / countSum("fillProfiles")) * 100)}%` : undefined,
      !tableConfig.includes("fpFtd") ? `${Math.round((countSum("ftd") / countSum("fillProfiles")) * 100)}%` : undefined,
      !tableConfig.includes("registrations") ? countSum("registrations") : undefined,
      !tableConfig.includes("fillProfiles") ? countSum("fillProfiles") : undefined,
      !tableConfig.includes("emailConfirmed") ? countSum("emailConfirmed") : undefined,
      !tableConfig.includes("signUps") ? countSum("signUps") : undefined,
      (choosedSiteId === 37 || choosedSiteId === 69) && !tableConfig.includes("sms4Plus35") ? countSum("sms4Plus35") : undefined,
      !tableConfig.includes("online") ? Math.floor(countSum("online") / countSum("fillProfiles")) : undefined,
      !tableConfig.includes("sales") ? countSum("sales") : undefined,
      !tableConfig.includes("ftd") ? countSum("ftd") : undefined,
      !tableConfig.includes("coins") ? countSum("coins") : undefined,
      !tableConfig.includes("money") ? countSum("money") : undefined,
    ].filter(i => i !== undefined);
    const csv = [
      [
        tabs.find((i) => i.id === activeGroupParam).label,
        ...headers,
      ],
      ...finalResults,
      [
        ...total,
      ],
    ];
    return csv;
  };

  const renderMainContent = () => {
    if (isEmpty) {
      return null;
    }
    if (loading) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MDBSpinner className="spinner-container" />
        </div>
      );
    }

    const detectMob = () => {
      return window.innerWidth <= 800;
    };

    const chartProps = (chartData) => {
      let props = {
        data: chartData,
        options: options,
      };
      if (!detectMob()) {
        props.height = 70;
      }
      return props;
    };

    const ColumnLink = ({ row, filterId, groupParam }) => {
      const onLinkPress = () => {
        const nonEmptyFilterForm = Object.keys(filterForm)
          .filter((k) => filterForm[k])
          .reduce((a, k) => ({ ...a, [k]: filterForm[k] }), {});
        const query = qs.stringify({
          ...nonEmptyFilterForm,
          groupParam,
          filterId,
          grouping: row.grouping,
          fromDate: dateForm.from,
          toDate: dateForm.to,
        });
        window.open(`${location.pathname}?${query}`, "_blank");
      };
      return (
        <strong
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={onLinkPress}
        >
          {formatDateMonth(row, true)}
        </strong>
      );
    };

    const renderFirstColumn = (row) => {
      if (activeGroupParam === "utm_source") {
        return (
          <ColumnLink
            row={row}
            filterId="utm_source"
            groupParam="utm_campaign"
          />
        );
      }
      if (activeGroupParam === "linkid") {
        return <ColumnLink row={row} filterId="linkid" groupParam="web_id" />;
      }
      if (activeGroupParam === "utm_campaign") {
        return (
          <ColumnLink row={row} filterId="utm_campaign" groupParam="utm_term" />
        );
      }
      if (activeGroupParam === "web_id") {
        return <ColumnLink row={row} filterId="web_id" groupParam="sub_id" />;
      }
      return <strong>{formatDateMonth(row, true)}</strong>;
    };

    const tableConfig = settingsConfig.columns;

    return (
      <MDBCardBody>
        <MDBCol lg="3" md="5">
          <CSVLink
            filename={`${
              tabs.find((i) => i.id === activeGroupParam).label
            }_${moment().format("DDMMYYYY")}`}
            data={generateCsv(sortedData([...data]))}
          >
            Export CSV
          </CSVLink>
        </MDBCol>

        {!isHided && (
          <>
            {activeGroupParam === "day" || activeGroupParam === "month" ? (
              <Line {...chartProps(lineChartData)} />
            ) : (
              <Bar {...chartProps(barChartData)} />
            )}
          </>
        )}
        <div
          style={{
            position: "absolute",
            top: 30,
            right: 30,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setIsHided(!isHided)}
        >
          <MDBIcon icon={!isHided ? "angle-double-down" : "angle-double-up"} />
        </div>

        {!isHided && (
          <MDBRow className="mb-2 mt-2">
            {graphOptionsTypes.map((type) => (
              <MDBInput
                id={type.id}
                onChange={() => setSelectedChartType(type.id)}
                checked={type.id === selectedChartType}
                type="radio"
                label={type.label}
              />
            ))}
          </MDBRow>
        )}
        <MDBTable responsive striped>
          <thead>
            <tr>
              <S.Th
                withSorting
                direction={sorting.direction}
                isSorting={sorting.field === "grouping"}
                onClick={() => onSortClick("grouping")}
              >
                <strong>
                  {tabs.find((i) => i.id === activeGroupParam).label}
                </strong>
              </S.Th>
                {TABLE_HEADERS.map((h) => {
                if (!h.siteIds || h.siteIds.includes(choosedSiteId)) {
                  if (tableConfig.includes(h.id)) return null;
                  return (
                    <S.Th
                      withSorting
                      direction={sorting.direction}
                      isSorting={sorting.field === h.id}
                      onClick={() => onSortClick(h.id)}
                    >
                      {h.header}
                    </S.Th>
                  );
                }
                return null;
              })}
            </tr>
          </thead>
          <tbody>
            {sortedData([...data]).map((row, index) => (
              <tr key={index}>
                <td>{renderFirstColumn(row)}</td>
                {!tableConfig.includes("fpSu") ? (
                  <td>{Math.round((row.signUps / row.fillProfiles) * 100)}%</td>
                ) : null}
                 {(choosedSiteId === 37 || choosedSiteId === 69) && !tableConfig.includes("fp4s") ? (
                  <td>{Math.round((row.sms4Plus35 / row.fillProfiles) * 100)}%</td>
                ) : null}
                {!tableConfig.includes("fpFtd") ? (
                  <td>{Math.round((row.ftd / row.fillProfiles) * 100)}%</td>
                ) : null}
                {!tableConfig.includes("registrations") ? (
                  <td>{row.registrations}</td>
                ) : null}
                {!tableConfig.includes("fillProfiles") ? (
                  <td>{row.fillProfiles}</td>
                ) : null}
                {!tableConfig.includes("emailConfirmed") ? (
                  <td>{row.emailConfirmed}</td>
                ) : null}
                 {!tableConfig.includes("signUps") ? (
                  <td>{row.signUps}</td>
                ) : null}
                {(choosedSiteId === 37 || choosedSiteId === 69) && !tableConfig.includes("sms4Plus35") ? (
                  <td>{row.sms4Plus35}</td>
                ) : null}
                {!tableConfig.includes("online") ? (
                  <td width="150">{Math.floor(row.online / row.fillProfiles)}</td>
                ) : null}
                {!tableConfig.includes("sales") ? <td>{row.sales}</td> : null}
                {!tableConfig.includes("ftd") ? (
                  <td>{row.ftd}</td>
                ) : null}
                {!tableConfig.includes("coins") ? (
                  <td>{row.coins}</td>
                ) : null}
                {!tableConfig.includes("money") ? (
                  <td>{row.money}</td>
                ) : null}
              </tr>
            ))}
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              {!tableConfig.includes("fpSu") ? 
              <td>
                <strong>{Math.round((countSum("signUps") / countSum("fillProfiles")) * 100)}%</strong>
              </td> : null}
              {(choosedSiteId === 37 || choosedSiteId === 69) && !tableConfig.includes("fp4s") ? 
              <td>
                <strong>{Math.round((countSum("sms4Plus35") / countSum("fillProfiles")) * 100)}%</strong>
              </td>: null}
              {!tableConfig.includes("fpFtd") ? 
              <td>
                <strong>{Math.round((countSum("ftd") / countSum("fillProfiles")) * 100)}%</strong>
              </td> : null}
              {!tableConfig.includes("registrations") ? 
              <td>
                <strong>{countSum("registrations")}</strong>
              </td> : null}
              {!tableConfig.includes("fillProfiles") ? (
                <td>
                  <strong>{countSum("fillProfiles")}</strong>
                </td>
              ) : null}
                {!tableConfig.includes("emailConfirmed") ? (
                <td>
                  <strong>{countSum("emailConfirmed")}</strong>
                </td>
              ) : null}
              {!tableConfig.includes("signUps") ? (
                <td>
                  <strong>{countSum("signUps")}</strong>
                </td>
              ) : null}
              {(choosedSiteId === 37 || choosedSiteId === 69) && !tableConfig.includes("sms4Plus35") ? (
                <td>
                  <strong>{countSum("sms4Plus35")}</strong>
                </td>
              ) : null}
              {!tableConfig.includes("online") ? (
                <td>
                  <strong>{Math.floor(countSum("online") / countSum("fillProfiles"))}</strong>
                </td>
              ) : null}
              {!tableConfig.includes("sales") ? (
                <td>
                  <strong>{countSum("sales")}</strong>
                </td>
              ) : null}
              {!tableConfig.includes("ftd") ? (
                <td>
                  <strong>{countSum("ftd")}</strong>
                </td>
              ) : null}
              {!tableConfig.includes("coins") ? (
                <td>
                  <strong>{countSum("coins")}</strong>
                </td>
              ) : null}
              {!tableConfig.includes("money") ? (
                <td>
                  <strong>{countSum("money")}</strong>
                </td>
              ) : null}
            </tr>
          </tbody>
        </MDBTable>
      </MDBCardBody>
    );
  };

  const onChangeSetting = (configGroup, id) => {
    const currentConfigGroup = settingsConfig[configGroup].includes(id)
      ? settingsConfig[configGroup].filter((i) => i !== id)
      : [...settingsConfig[configGroup], id];

      const newSettingsConfig = {
        ...settingsConfig,
        [configGroup]: currentConfigGroup,
      }
    setSettingsConfig(newSettingsConfig);
    localStorage.setItem('settings-config', JSON.stringify(newSettingsConfig));
  };

  const toggleAllSelectSettings = (newConfig) => {
    setSettingsConfig(newConfig);
    localStorage.setItem('settings-config', JSON.stringify(newConfig));
  };

  return (
    <S.Container>
      <MDBCard className="p-2 mb-5">
        <MDBCardBody>
          <MDBRow>
            <MDBTabs className="mb-3">
              {groupParams.map((param) => {
                if (settingsConfig.groups.includes(param.id)) return null;
                return (
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => {
                        setEmpty(true);
                        setActiveGroupParam(param.id);
                      }}
                      active={activeGroupParam === param.id}
                    >
                      {param.label}
                    </MDBTabsLink>
                  </MDBTabsItem>
                );
              })}
            </MDBTabs>
            <div style={{ marginLeft: "auto" }}>
              <MDBBtn
                disabled={!dateForm.to || !dateForm.from}
                onClick={loadData}
              >
                Apply
              </MDBBtn>
            </div>
          </MDBRow>

          <hr />
          <MDBRow className="mt-2">
            {!settingsConfig.filters.includes("date") ? (
              <DateInput
                value={dateForm}
                onChange={(value) => setDateForm(value)}
              />
            ) : null}
            {renderInput("Linkid", "linkid")}
            {renderInput("WebId", "web_id")}
            {renderInput("SubId", "sub_id")}
          </MDBRow>
          <MDBRow className="mt-2">
            {renderInput("Utm_Source", "utm_source")}
            {!isToggled
              ? renderInput("Age", "age")
              : renderDropdown("Age", "age", ageOptions)}
            {renderInput("Country", "ipinfo_country")}
            {renderInput("Utm_Term", "utm_term")}

          </MDBRow>
          <MDBRow className="mt-2">
            {renderInput("Utm_Campaign", "utm_campaign")}
            {renderDropdown("OS", "os", osOptions)}
            {renderDropdown("Partner", "partner", partnerOptions)}
            {renderInput("Landing", "path")}
          </MDBRow>
          <MDBRow className="mt-2">
            {renderInput("Utm_Content", "utm_content")}
          </MDBRow>
          <MDBRow className="mt-2">
            {choosedSiteId === 15 && renderSelect("User Role", "userrole")}
          </MDBRow>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
              <div style={{ cursor: 'pointer' }} onClick={() => setSettingsVisible(true)}>
                <MDBIcon icon='landmark' color='red' size='lg' />     
              </div>
            </div>

        </MDBCardBody>
      </MDBCard>
      <MDBCard className="p-2 mb-5">{renderMainContent()}</MDBCard>
      <SettingsModal
        settingsConfig={settingsConfig}
        onChangeSetting={onChangeSetting}
        visible={isSettingsVisible}
        toggleAllSelectSettings={toggleAllSelectSettings}
        onClose={() => setSettingsVisible(false)}
      />
    </S.Container>
  );
};

export default Statistics;
